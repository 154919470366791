body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'DIN Pro Cond';
  src: url('./assets/fonts/DINPro-CondensedBold.woff2') format('woff2'),
    url('./assets/fonts/DINPro-CondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Happy Camper';
  src: url('./assets/fonts/Happy-Camper-Regular.woff2') format('woff2'),
    url('./assets/fonts/Happy-Camper-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

div.bm-overlay {
  top: 0;
  left: 0;
}

div.bm-menu-wrap {
  top: 0;
}

#root {
  width: 100vw;
  height: 100vh;
}

.bm-burger-button {
  display: none;
}
